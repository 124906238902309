<template>
  <div class="task" v-if="tasks">
    <div class="padding_top"></div>

    <b-container>
      <b-row class="task_editor_header" align-h="center">
        <b-form-checkbox
          v-model="editingTask"
          name="check-button"
          size="lg"
          switch
        >
          Редактирование
        </b-form-checkbox>
      </b-row>
    </b-container>

    <b-container v-if="editingTask">
      <b-row align-h="center">
        <b-col cols="12" md="8">
          <div class="videoTask embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              :src="'https://www.youtube.com/embed/' + tasks[active].video"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col cols="12" md="8">
          <label for=""
            >Ссылка на видео (только id видео с youtube, без адреса)</label
          >
          <b-form-input
            id="name"
            v-model="tasks[active].video"
            type="text"
            placeholder="iMTppDFZ9t8"
            required
          ></b-form-input>

          <label for="">Название*</label>
          <b-form-textarea
            id="textarea"
            v-model="tasks[active].title"
            rows="2"
            max-rows="6"
            required
          ></b-form-textarea>

          <label for="">Описание</label>
          <b-form-textarea
            id="textarea"
            v-model="tasks[active].description"
            rows="2"
            max-rows="6"
            required
          ></b-form-textarea>

          <label for="">Текст*</label>
          <vue-editor
            class="task_text"
            v-model="tasks[active].text"
          ></vue-editor>
        </b-col>
      </b-row>

      <b-row class="answers_row" align-h="center">
        <b-col cols="12" md="8">
          <label for="">Вопросы</label>
          <div>
            <div
              class="question_area"
              v-for="(question, index) in tasks[active].questions"
              :key="index"
            >
              <b-form-textarea
                id="textarea"
                v-model="question.question"
                rows="3"
                max-rows="6"
                required
              ></b-form-textarea>

              <b-button
                type="button"
                @click="tasks[active].questions.splice(index, 1)"
                variant="outline-danger"
                class="delete_question_button"
              >
                X
              </b-button>
            </div>

            <b-button
              block
              type="button"
              @click="tasks[active].questions.push({ question: '' })"
              variant="outline-secondary"
              class="add_question_button"
            >
              Добавить вопрос
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-else>
      <b-row align-h="center" v-if="tasks[active].video">
        <b-col cols="12" md="8">
          <div class="videoTask embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              :src="'https://www.youtube.com/embed/' + tasks[active].video"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col cols="12" md="8">
          <h1 class="task__title" v-if="tasks[active].title">
            {{ tasks[active].title }}
          </h1>

          <h2 class="task__description" v-if="tasks[active].description">
            {{ tasks[active].description }}
          </h2>

          <p
            v-if="tasks[active].text"
            class="task__text"
            v-html="tasks[active].text"
          ></p>
        </b-col>
      </b-row>

      <b-row
        class="answers_row"
        align-h="center"
        v-if="tasks[active].questions"
      >
        <b-col cols="12" md="8">
          <b-form class="modal__form">
            <div>
              <div
                v-for="(question, index) in tasks[active].questions"
                :key="index"
              >
                <p>{{ question.question }}</p>
                <b-form-textarea
                  id="textarea"
                  v-model="question.answer"
                  placeholder="Введите ответ..."
                  rows="3"
                  max-rows="6"
                  required
                ></b-form-textarea>
              </div>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </b-container>

    <b-container>
      <b-row class="task_editor_footer" align-h="between" align-v="center">
        <b-col cols="6">
          <b-button
            :disabled="loading"
            v-if="type == 'edit'"
            type="button"
            @click="save"
            class="main__button modal_form__buttom success_button"
          >
            Сохранить
          </b-button>
        </b-col>

        <b-col cols="6">
          <b-button
            block
            :disabled="loading"
            v-if="tasks.length - 1 == active"
            type="button"
            @click="deleteTask()"
            variant="outline-danger"
          >
            удалить задание
          </b-button>

          <b-button
            block
            disabled
            v-else
            type="button"
            variant="outline-danger"
          >
            удалить можно только последнее задание в уровне
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import { VueEditor } from "vue2-editor";

export default {
  name: "Task",
  components: {
    VueEditor,
  },
  data() {
    return {
      editingTask: true,
    };
  },
  methods: {
    ...mapActions(["getAdminTasks", "completeTask", "editTask", "getUser"]),
    async save() {
      try {
        this.$store.commit("SET_LOADING", true);

        await axios.post(
          `/admin/tasks/${this.tasks[this.active].id}`,
          this.tasks[this.active]
        );
        this.$bvToast.toast("Сохранено");

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);

        this.$bvToast.toast(`${e}`);
        console.error(e);
      }
    },
    async deleteTask() {
      try {
        this.$store.commit("SET_LOADING", true);

        await axios.delete(`/admin/tasks/${this.tasks[this.active].id}`);
        this.$bvToast.toast("Задание удалено");

        this.$router.push({ name: "TasksEditor" });

        this.$store.commit("SET_LOADING", false);
      } catch (error) {
        this.$store.commit("SET_LOADING", false);

        this.$bvToast.toast(`${e}`);
      }
    },
    async levelChanger() {
      try {
        this.$store.commit("SET_LOADING", true);

        await this.getAdminTasks({ level: this.selectedLevel });

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    },
  },
  computed: {
    tasks() {
      return this.$store.state.bkonline.tasks;
    },
    user() {
      return this.$store.state.user.user;
    },
    type() {
      return localStorage.getItem("typeTask");
    },
    active() {
      return localStorage.getItem("activeTask");
    },
    loading() {
      return this.$store.state.loading;
    },
  },
  async mounted() {
    if (localStorage.getItem("token")) {
      try {
        this.$store.commit("SET_LOADING", true);

        await this.getUser();

        if (this.user.role_id == 1) {
          await axios.get("/admin/levels").then((res) => {
            this.levels = res.data;
          });

          await this.levelChanger();
        } else {
          this.$bvToast.toast(
            "У вас нет прав администратора для редактирования заданий"
          );
        }

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    } else {
      this.$store.commit("SET_LOADING", false);

      this.$router.push({ name: "Login" });
    }
  },
};
</script>

<style lang="scss">
.task__title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 30px;
}
.task__description {
  color: rgba($color: $light_gray__medium, $alpha: 0.9);
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 80px;
  margin-top: 30px;
}
.task__text {
  word-wrap: break-word;
  font-weight: 500;
  white-space: pre-wrap;
  margin-bottom: 10vh;
  font-size: 22px;
  color: $light_gray__dark;
}
.success_button {
  outline: none;
  border: none;
  background-color: #008736;
}
.success_button:hover,
.success_button:focus,
.success_button:focus-visible {
  outline: none;
  border: none;
  background-color: rgba($color: #008736, $alpha: 0.9);
}

.question_area {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete_question_button {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  font-size: 14px;
  margin-left: 10px;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 800;
  color: rgba($color: $white, $alpha: 0.5);
}

.task_editor_footer {
  margin-top: 100px;
}

.task_editor_header {
  margin-bottom: 30px;
}
</style>
